import {useEffect, useState} from "react";
import {Api} from "/api";
import {useDispatch, useSelector} from "react-redux";
import Body from "/components/pages/product/body"
import Loader from "/components/common/loader"
import styles from "./index.module.scss";

export default function FastView() {
    const {data: productCode} = useSelector(state => state.popup)
    const [product, setProduct] = useState({})
    const [productLoaded, setProductLoaded] = useState(false)

    useEffect(async () => {
        const product = await Api.server.getProduct(productCode)
        setProduct(product)
        setProductLoaded(true)
    }, [])

    return (
        <div className={styles.Wrapper}>
            {!productLoaded && (
                <div className={styles.Loader}>
                    <Loader/>
                </div>
            )}
            {productLoaded && (
                <Body product={product} type={'Быстрый просмотр'}/>
            )}
        </div>
    )
}
