import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import styles from '../index.module.scss'
import clsx from "clsx";
import Button from "@/components/common/button"
import Loader from "@/components/common/loader"
import {setDeliveryListAdditional} from "@/redux/action-creaters/cart";
import {Api} from "@/api";
import {showPopup} from "@/redux/action-creaters/popup";
import {popupTypes} from "@/redux/reducers/popupReducer";
import {getEdostMinPriceCorrected} from "@/utils";
import {DeliveryTypes} from "~/types/cart";

export default function Edost({onBuy, canBuy, product, isShow}: any) {
    const {cityName, deliveyCityData, deliveyCityDataLoaded} = useSelector((state: any) => state.location)
    const dispatch = useDispatch();
    const [edost, setPoints] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const {deliveryList} = useSelector((state: any) => state.cart)

    const edostMinPriceCorrected: number = useMemo(() => {
        return 0
    }, [product]);

    const getEdostPoints = async () => {
        setIsLoading(true)
        const points = await Api.edost.getCalc({
            city_name_new: cityName,
            region_name: `${deliveyCityData?.region} ${deliveyCityData?.region_type_full}`,
            kladr_id: deliveyCityData?.kladr_id,
            productId: product.ID,
            productPrice: product.CATALOG.PRICE + edostMinPriceCorrected
        })
        //const host ='krasnodar.farkop.ru'.split('.')[0];
        let host = '';
        if (typeof window !== "undefined") {
            host = window.location.host.split('.')[0];
        }
        const edostItem = deliveryList.find((item: any) => {
            return item['TYPE'] === DeliveryTypes.edost
        })
        console.log('test', edostItem)
        const edostDiscountPrice = edostItem.DISCOUNT?.[host] || 0;
        let minPriceVal = -1;
        let tarrifs = points['TARIFFS'] ? points['TARIFFS'].map((item: any) => {
            let price = parseInt(item.price);
            if (price < 0) {
                price = 0;
            }
            if (minPriceVal < 0 || price < minPriceVal) {
                minPriceVal = price;
            }
            return {
                ...item,
                price:  parseInt(item.price) - edostDiscountPrice,
                discount: edostDiscountPrice,
                oldPrice: parseInt(item.price),
            }
        }) : [];
        /*if (minPriceVal > 0 && edostMinPrice > 0) {
            tarrifs = tarrifs.map((item: any) => {
                if (item.price === minPriceVal) {
                    return {
                        ...item,
                        price: 0
                    }
                }
                return item
            });
            minPriceVal = 0;
        }*/

        setPoints(tarrifs)
        dispatch(setDeliveryListAdditional(points['DELIVERY_LIST']?.map((item: any) => {
            let PRICE = item.PRICE;
            let DESCRIPTION = item.DESCRIPTION;
            let ID = parseInt(item.ID);
            let IS_PRICE_FROM = false;
            if (ID == 4) {
                IS_PRICE_FROM = true;
                PRICE = minPriceVal;
                //DESCRIPTION = minPriceVal > 0 ? `от ${minPriceVal} руб` : 'бесплатно';
            }
            if (ID == 39) {
                DESCRIPTION = `${item.PRICE} руб`;
            }
            return {
                ID,
                NAME: item['NAME'],
                IS_PRICE_FROM,
                DESCRIPTION,
                PARENT_ID: '',
                PRICE,
                TYPE: item['TYPE'],
                LOCATION: '',
                PRICE_RESTRICTIONS: null,
            };
        })));
        setIsLoading(false)
    };

    useEffect(() => {
        if (cityName && deliveyCityDataLoaded) {
            getEdostPoints()
        }
    }, [cityName, deliveyCityDataLoaded]);

    const edostFormated = useMemo(() => {
        return edost ? edost.sort((a: any, b: any) => a.price - b.price) : []
    }, [edost])

    /*const edostMinPriceVal = useMemo(() => {
        let min = -1
        edostFormated.map((item: any) => {
            if (min < 0 || (item.price) < min) {
                min = (item.price);
            }
        })
        return min
    }, [edostFormated])

    const edostCurierPrice = useMemo(() => {
        let minPrice: any = null;
        Array.isArray(edost) && edost.map((item: any) => {
            if (
                (item.name.indexOf('курьером') > -1 || item.name.indexOf('склад-дверь') > -1) &&
                (!minPrice || minPrice > item.price)
            ) {
                minPrice = item.price
            }
        })
        return minPrice
    }, [edost])

    useEffect(() => {
        const DESCRIPTION = edostMinPriceVal > 0 ? `от ${edostMinPriceVal} руб` : 'бесплатно'
        const additionals = [{
            ID: 4,
            NAME: "Доставка в пункт выдачи",
            DESCRIPTION,
            TYPE: "edost",
        }];
        if (edostCurierPrice) {
            additionals.push({
                ID: 39,
                NAME: "Доставка курьером до двери",
                DESCRIPTION: `${edostCurierPrice} руб`,
                TYPE: "edostCourier",
            })   
        }
        dispatch(setDeliveryListAdditional(additionals));
    }, [edostMinPriceVal, edostCurierPrice]);*/

    const {user} = useSelector((state: any) => state.user)


    return isShow && (
        <>
             {isLoading && (
                <div className={styles.Loader}>
                    <Loader/>
                </div>
            )}
            {!isLoading && (
                <>
                    {edostFormated.length === 0 ? (
                        <div>
                            <p>Не удалось рассчитать доставку в ваш город. Выберите другой город или уточните у менеджера способы доставки:</p>
                            <div>
                                <Button customStyles={styles.FormButton} onClick={() => dispatch(showPopup(popupTypes.backCall))} label={'Уточнить способы доставки'}/>
                            </div>
                        </div>
                    ) : (
                        <div className={clsx(styles.TableMiddle,styles.Table)}>
                            <span className={styles.Head}>Название компании</span>
                            <span className={styles.Head}>Сроки доставки</span>
                            <span className={styles.Head}>Стоимость</span>
                            <span className={styles.HeadLast}></span>
                            {edostFormated.map((item: any) => (
                                <>
                                    <span>{item.company}</span>
                                    <span>{item.day}</span>
                                    <span>
                                        {item.price > 0 ? (
                                            <>
                                                {item.discount > 0 && (
                                                    <span className={styles.OldPrice}>{item.oldPrice} руб</span>
                                                )}
                                                <div>{parseInt(item.price)} руб</div>
                                            </>

                                        ) : (
                                            <div>бесплатно</div>
                                        )}
                                    </span>
                                    <span className={styles.TableButton}>
                                        {canBuy && (
                                            <Button label={'Оформить покупку'} type={'outline'} onClick={onBuy}/>
                                        )}
                                    </span>
                                </>
                            ))}
                        </div>
                    )}
                </>
            )}
        </>
    )
}