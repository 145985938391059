import styles from '../index.module.scss'
import clsx from "clsx";
import Button from "@/components/common/button"

export default function Sdek({onBuy, canBuy, isShow}: any) {
    return isShow && (
        <div className={clsx(styles.TableSmall,styles.Table)}>
            <span className={styles.Head}>Условия доставки</span>
            <span className={styles.Head}>Стоимость</span>
            <span className={styles.HeadLast}></span>
            <span>Доставка в пункт самовывоза СДЭК</span>
            <span>от 400 рублей</span>
            <span className={styles.TableButton}>
                {canBuy && (
                    <Button label={'Оформить покупку'} type={'outline'} onClick={onBuy}/>
                )}
            </span>
        </div>
    )
}