import {useMemo} from "react";
import styles from './index.module.scss'
import Sldier from '/components/common/slider'

export default function Images({images, video, alt}) {
    const videosFormated = useMemo(() => {
        if (video) {
            let videos = video;
            if (!Array.isArray(videos)) {
                videos = [videos];
            }

            return videos.map(video => {
                if (video.includes('vk.com')) {
                    return {
                        thumb: 'https://downloadr2.apkmirror.com/wp-content/uploads/2022/12/89/6391a81ca2013.png',
                        full: video,
                        isVideo: true,
                    }
                }
                if (video.includes('rutube.ru')) {
                    let full = video;
                    if (full.includes('/video/')) {
                        const fullItems = full.split('/').filter(item => item);
                        full = `https://rutube.ru/play/embed/${fullItems[fullItems.length - 1]}/`;
                    }
                    return {
                        thumb: 'https://cs13.pikabu.ru/post_img/big/2023/06/11/11/1686509294231898515.png',
                        full,
                        isVideo: true,
                    }
                }
                let videoCode = video.match(/[?&]v=([^&]*)/);
                if (!videoCode || !videoCode[1]) {
                    videoCode = video.match(/be[/]*(.+)/);
                }
                if (videoCode && videoCode[1]) {
                    return {
                        thumb: `https://img.youtube.com/vi/${videoCode[1]}/default.jpg`,
                        full: `https://www.youtube.com/embed/${videoCode[1]}?rel=0&amp;autoplay=1`,
                        isVideo: true,
                    }
                }
                return null
            }).filter(video => video)

        }
        return []
    }, [video])

    const imagesFormated = useMemo(() => {
        if (videosFormated.length > 0) {
            if (images) {
                return [
                    images[0],
                    videosFormated[0],
                    ...images.slice(1),
                    ...videosFormated.slice(1)
                ]
            }
            return [
                videosFormated[0],
                ...videosFormated.slice(1)
            ]
        }
        return images
    }, [images, videosFormated])

    return (
        <div className={styles.Images}>
            {imagesFormated?.length > 0 ? (
                <Sldier images={imagesFormated} alt={alt}/>
            ) : (
                <img src='/no_photo.jpeg'/>
            )}
        </div>
    )
}