import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchMainAdv} from "../../../redux/action-creaters/content";
import styles from './index.module.scss'
import clsx from "clsx";

export default function Adv({rows = 3}) {
    const dispatch = useDispatch();
    const {mainAdv} = useSelector(state => state.content)

    return (
        <div className={styles.Adv}>
            <div className={clsx(styles.AdvList, styles[`AdvListRow_${rows}`])}>
                {mainAdv.map(adv => (
                    <div key={adv.ID}>
                        <div dangerouslySetInnerHTML={{__html: adv['~PROPERTY_SVG_ICON_VALUE']?.TEXT}}></div>
                        <span>{adv.NAME}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}