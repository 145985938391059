import styles from '../index.module.scss'
import clsx from "clsx";
import Button from "@/components/common/button"

export default function Courier({contactsData, onBuy, canBuy, isShow}: any) {
    return isShow && (
        <>
            <div className={clsx(styles.TableSmall,styles.Table)}>
                <span className={styles.Head}>Условия доставки</span>
                <span className={styles.Head}>Стоимость</span>
                <span className={styles.HeadLast}></span>
                {contactsData?.COURIER_TERMS?.NAME?.map((name: any, key: any) => (
                    <>
                        <span className={styles.Adress}>{name}</span>
                        <span>{contactsData?.COURIER_TERMS.PRICE[key]} руб</span>
                        <span className={styles.TableButton}>
                            {canBuy && (
                                <Button label={'Оформить покупку'} type={'outline'} onClick={onBuy}/>
                            )}
                        </span>
                    </>
                ))}
            </div>
            <div className={styles.TableDescription} dangerouslySetInnerHTML={{__html: contactsData?.DELIVERY_TEXT}} />
        </>
    )
}