import styles from './index.module.scss'
import Icon from "../../icon";
import Button from "../../button";
import {addToCart, getBacket} from "../../../../redux/action-creaters/cart";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {useRouter} from "next/router";
import {showPopup} from "/redux/action-creaters/popup";
import {popupTypes} from "/redux/reducers/popupReducer";
import {getEdostMinPriceCorrected} from "/utils"
import {Api} from "/api";

export default function Offer({
    name,
    productId,
    product,
    catalog,
    isDetail = false,
    isBaltex
}) {
    const {sections} = useSelector(state => state.sections)

    const dispatch = useDispatch();
    const router = useRouter();
    const [isAddingToCart, setIsAddingToCart] = useState(false)

    const {basket} = useSelector(state => state.cart)

    const isInCart = useMemo(() => {
        return basket?.ITEMS?.find(item => productId == item.PRODUCT_ID)
    }, [basket])
        
    const quantity = useMemo(() => {
        return catalog.QUANTITY;
    }, [catalog]);
    
    const isAlailable = useMemo(() => {
        return catalog.AVAILABLE;
    }, [catalog]);

    const price = useMemo(() => {
        return catalog.MIN_PRICE;
    }, [catalog]);

    const noDiscoutPrice = useMemo(() => {
        return catalog.PRICE;
    }, [catalog]);

    const edostMinPriceCorrected = useMemo(() => {
        return 0;
    }, [catalog]);

    const priceWithCity = useMemo(() => {
        return price + edostMinPriceCorrected;
    }, [edostMinPriceCorrected])

    const {user} = useSelector(state => state.user)

    return (
        <>
            {!isBaltex && (
                <div className={styles.Qty}>
                    {quantity > 0 ? (
                    <>
                        <Icon id='yes' width={18} height={18}/>
                        <span className={styles.InStock}>В наличии: {quantity} шт</span>
                    </>
                    ): (<span className={styles.OutOfStock}>Нет в наличии</span>)}
                </div>
            )}
            <div className={styles.Price} itemProp="offers" itemScope itemType="http://schema.org/Offer">
                {isAlailable && noDiscoutPrice > price &&
                    <div className={styles.BeforeValue}>{noDiscoutPrice} руб</div>}

                {!isBaltex && <div className={styles.Value}> {isAlailable && (<><span itemProp="price">{priceWithCity} руб</span></>)}</div>}
                {isBaltex && <div className={styles.ValueBaltex}>
                    <div className={styles.BaltexArt}>
                        <span>Арт. {product.ARTICLE?.VALUE}</span>
                    </div>
                    {isAlailable && (<><span itemProp="price">{priceWithCity} руб</span></>)}
                </div>}
                {isBaltex && <div className={styles.BaltexBottom}>
                    <div className={styles.Qty}>
                        {quantity > 0 ? (
                            <>
                                <span className={styles.InStock}>В наличии: {quantity} шт</span>
                            </>
                        ) : (<span className={styles.OutOfStock}>Нет в наличии</span>)}
                    </div>
                </div>}

                <span itemProp="priceCurrency" style={{display: "none"}}>RUB</span>
                {isAlailable ? (
                    <>
                        <link itemProp="availability" href="http://schema.org/InStock"/>
                        <div className={styles.Buttons}>
                            {isBaltex && (
                                <div>
                                    <Button onClick={async () => {
                                        window.open(`https://farkop.ru${product.DETAIL_PAGE_URL}`, '_blank');
                                    }} label={'Купить'}/>
                                    <div className={styles.ParnersText}>Покупка производится на сайте партнера
                                        farkop.ru
                                    </div>
                                </div>
                            )}
                            {!isBaltex && <Button type={isInCart ? 'outline' : ''} onClick={async () => {
                                if (!isInCart) {
                                    setIsAddingToCart(true)
                                    await Api.basket.addToBasket(productId, priceWithCity);
                                    await dispatch(getBacket(true))
                                    await dispatch(showPopup(popupTypes.cart))
                                    setIsAddingToCart(false)

                                    if (typeof ym !== "undefined") {
                                        ym(20518312, 'reachGoal', 'addToCart');
                                    }
                                } else {
                                    router.push('/personal/cart/')
                                }

                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    "ecommerce": {
                                        "currencyCode": "RUB",
                                        "add": {
                                            "products": [
                                                {
                                                    "id": productId,
                                                    "name": name,
                                                    "price": price,
                                                    "quantity": 1,
                                                    "category": sections[product.IBLOCK_SECTION_ID].NAME,
                                                    "brand": product.BRAND?.name,
                                                    "list": isDetail ? "Детальная страница" : "Каталог",
                                                }
                                            ]
                                        }
                                    }
                                });
                            }} label={isInCart ? 'В корзине' : 'Купить'} isLoading={isAddingToCart}/>}
                            {!isBaltex && <Button type='outline' label="Купить в 1 клик" onClick={() => {
                                dispatch(showPopup(popupTypes.buyOneClick, {id: productId, price: priceWithCity}))
                            }}/>}
                        </div>
                        <span className={styles.Separator}/>
                        <div
                            className={styles.Link}
                            onClick={() => {
                                dispatch(showPopup(popupTypes.findLowPrice, {
                                    name,
                                    id: productId,
                                    price: priceWithCity
                                }))
                            }}
                        >Нашли дешевле?
                        </div>
                    </>
                ) : (
                    <>
                        <Button
                            type='outline'
                            label={'Оформление предзаказа'}
                            onClick={() => {
                                dispatch(showPopup(popupTypes.subscribeProduct, {
                                    name: `${product.NAME} (арт ${product.ARTICLE?.VALUE}) (id ${product.ID})`,
                                    id: productId
                                }))
                            }}
                        />
                    </>
                )}
            </div>
        </>
    )
}