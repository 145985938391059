import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import Icon from "../../../common/icon";

export default function Brand({data}) {
    return data?.name ? <div className={styles.Brand}>
        <a href={data.detalPageUrl}>
            <img src={data.pictureSrc}/>
        </a>
    </div> : null;
}