import {useEffect, useMemo, useState} from "react";
import {changeQty, getBacket, removeFromCart} from "../../../redux/action-creaters/cart";
import {useDispatch, useSelector} from "react-redux";
import styles from "./index.module.scss";
import Link from 'next/link'
import {useRouter} from "next/router";
import Icon from "/components/common/icon";
import {Api} from "/api";

export default function BasketItems({basket}) {
    const dispatch = useDispatch();

    const [deletingId, setDeletingId] = useState(0)
    const [changingId, setChangingId] = useState(0)
    const {sections} = useSelector(state => state.sections)

    return (
        <div className={styles.Items}>
            {basket?.ITEMS?.map(item => (
                <div className={styles.Item} key={item.ID}>
                    <div className={styles.Photo}>
                        <Link href={item.URL ?? '/'}><a><img src={item.PICTURE}></img></a></Link>
                    </div>
                    <div className={styles.Other}>
                        <div className={styles.Name}>
                            <Link href={item.URL ?? '/'}><a>{item.NAME}</a></Link>
                           {item.NO_DISCOUNT_PRICE_TOTAL > 0 && <div className={styles.PriceMobileOld}>
                                {item.NO_DISCOUNT_PRICE_TOTAL} руб
                            </div>}
                            <div className={styles.PriceMobile}>
                                {item.PRICE_TOTAL} руб
                            </div>
                            {item.ELECTRIC === 'нет' && (
                                <a className={styles.Electric} href={`/catalog/elektrika_farkopov/filter/${item.FILTER_URL}`} rel="noreferrer" target="_blank">Подобрать электрику</a>
                            )}
                        </div>
                        <div className={styles.Qty}>
                            <div onClick={async () => {
                                setChangingId(item.ID)
                                await Api.basket.changeBasketQty({id: item.PRODUCT_ID, qty: item.QUANTITY - 1})
                                await dispatch(getBacket(true))
                                setChangingId(0)
                            }}>
                                <Icon id='minus' width={22} height={2}/>
                            </div>
                            <span>
                                {changingId && changingId === item.ID ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
                                        <path fill="#333" d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z">
                                            <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.5s" repeatCount="indefinite"/>
                                        </path>
                                    </svg>
                                ) : item.QUANTITY}
                            </span>
                            <div onClick={async () => {
                                setChangingId(item.ID)
                                await Api.basket.changeBasketQty({id: item.PRODUCT_ID, qty: item.QUANTITY + 1})
                                await dispatch(getBacket(true))
                                setChangingId(0)
                            }}>
                                <Icon id='plus' width={22} height={22}/>
                            </div>
                        </div>
                     
                        <div className={styles.Price}>
                            {item.DISCOUNT_PRICE > 0 && <div className={styles.PriceOld}>
                                {item.NO_DISCOUNT_PRICE_TOTAL} руб
                            </div>}
                            {item.PRICE_TOTAL} руб
                        </div>
                    </div>
                    <div
                        className={styles.Delete}
                        onClick={async () => {
                            if (deletingId) return
                            setDeletingId(item.ID)
                            await Api.basket.removeFromBasket(item.ID)
                            await dispatch(getBacket(true))
                            setDeletingId(0)

                            window.dataLayer = window.dataLayer || [];
                            dataLayer.push({
                                "ecommerce": {
                                    "currencyCode": "RUB",
                                    "remove": {
                                        "products": [
                                            {
                                                "id": item.ID,
                                                "name": item.NAME,
                                                "category": sections[item.SECTION_ID].NAME,
                                                "quantity": item.QUANTITY,
                                                "list": "Страница корзины",
                                            }
                                        ]
                                    }
                                }
                            });
                        }}
                    >
                        {deletingId && deletingId === item.ID ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
                                <path fill="#333" d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z">
                                    <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.5s" repeatCount="indefinite"/>
                                </path>
                            </svg>
                        ) : (
                            <Icon id='trash' fill={'#8d8d8d'} width={20} height={20}/>
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}