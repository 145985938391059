import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import Icon from "../../../common/icon";

export default function Sert({sert, isBaltex}) {

    return sert?.length > 0 ?
        <div>{sert.map(item => (<div className={styles.Files} key={item.NAME}>
            <a href={item.URL} className={styles.File} rel="noreferrer" target="_blank">
                <Icon fill={'#C63737'} id={isBaltex ? 'baltexpdf' : 'pdf'} width={18} height={20}/>
                <div className={styles.Name}>
                    <div>{item.NAME}</div>
                </div>
            </a>
        </div>))}
    </div> : null;
}