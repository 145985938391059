import Input from "../../common/input";
import {useEffect, useMemo, useState} from "react";
import {Api} from "/api";
import styles from "./index.module.scss";
import {useDispatch, useSelector} from "react-redux";
import PopupFormWrapper from "../popupFormWrapper"
import Mini from "/components/common/product/mini"

export default function AddQuestion() {
    const dispatch = useDispatch();
    const [fields, setFields] = useState({});
    const [result, setResult] = useState({});
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const masks = {
        phone: '+9 (999) 999 99-99'
    }

    const submit = async () => {
        setIsProcessing(true)
        const data = await Api.forms.addQuestion(result);
        if (data.success) {
            setSuccess(`Вопрос отправлен. Номер вашего вопроса #${data.success}`)
        } else if (data.message) {
            setError(data.message)
            setFields(data.fields);
        }
        setIsProcessing(false)
    }

    const {data} = useSelector(state => state.popup)

    useEffect(async () => {
        setLoading(true)
        const {fields} = await Api.forms.addQuestion({getFields: true})
        setResult({ID: data.id, DOMAIN: window.location.host.split('.')[0]});
        setFields(fields);
        setLoading(false)
    }, [])

    const fieldsFiltered = useMemo(() => {
        return Object.values(fields).filter(({CODE}) => CODE !== 'ID' && CODE !== 'DOMAIN')
    }, [fields])

    return (
        <PopupFormWrapper
            title='Задать вопрос о товаре'
            isLoading={isLoading}
            isProcessing={isProcessing}
            onSubmit={submit}
            errorText={error}
            successText={success}
        >
            <Mini productId={data.id}/>
            <p>Вы также можете задать вопрос в  <a href='https://telegram.me/farkop_rubot' target='_blank' rel="noreferrer">
                Telegram
            </a> или <a href='https://wa.me/+79811779326' target='_blank' rel="noreferrer">
                Whatsapp
            </a>
            </p>
            {fieldsFiltered.map(({NAME, CODE, REQUIRED, ERROR, TYPE}) => (
                <Input
                    key={CODE}
                    label={NAME}
                    required={REQUIRED}
                    mask={masks[CODE]}
                    onChange={(value) => setResult({...result, [CODE]: value})}
                    isError={ERROR}
                    isMultiple={TYPE === 'HTML'}
                />
            ))}
        </PopupFormWrapper>
    )
}
