import styles from "./index.module.scss";
import React from "react";

export default function Balloon({point, onSelectPoint}) {

    return (
        <div className={styles.BalloonPoint}>
            {point['ADR']}<br/>
            {point['WORK_TIME']}
        </div>
    )
}
