import {Api} from "/api";
import {cacheFunctionData, getCookieDomain} from '/utils'
import Cookies from 'js-cookie'


export const getDeliveryCityData = (cityData = {}, reset = false) => {
    return async (dispatch) => {
        let deliveyCityData = {}
        if (reset) {
            deliveyCityData = cityData
            if (typeof window === 'object') {
                var date = new Date();
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
                Cookies.set('deliveyCityData', JSON.stringify({
                    kladr_id: deliveyCityData.kladr_id,
                    region_with_type: deliveyCityData.region_with_type,
                    region_type_full: deliveyCityData.region_type_full,
                    region: deliveyCityData.region,
                }), {
                    domain: getCookieDomain(),
                    path: '/',
                    expires: date
                })
            }
        } else {
            try {
                let deliveyCityDataJson = Cookies.get('deliveyCityData');
                if (deliveyCityDataJson) {
                    deliveyCityData = JSON.parse(deliveyCityDataJson)
                }
            } catch(e) {
                console.log('Error getting deliveyCityData', e)
            }
        }

        dispatch({type: 'SET_DELIVERY_CITY_DATA', payload: deliveyCityData})
        dispatch({type: 'SET_DELIVERY_CITY_DATA_LOADED', payload: true})
    }
}

export const setLocation = (cityName) => {
    return async (dispatch) => {
        if (typeof window === 'object') {
            var date = new Date();
            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
            Cookies.set('cityName', cityName, {
                domain: getCookieDomain(),
                path: '/',
                expires: date
            })
        }
        dispatch({type: 'SET_CITY_NAME', payload: cityName})
    }
}

export const getEdostMinDays = (cityName, kladr = '') => {
    return async (dispatch) => {
        if (cityName) {
            const edostMinDays = await Api.server.getEdostMinDays(cityName, kladr);
            dispatch({type: 'SET_EDOST_MIN_DAYS', payload: edostMinDays})
        }
    }
}

export const fetchShops = (Redis = false, reset = false) => {
    return async (dispatch) => {
        const shops = await Api.location.getShops(Redis, reset)
        dispatch({type: 'FETCH_SHOPS', payload: shops})
    }
}

export const fetchEdostPoints = (cityName) => {
    return async (dispatch) => {
        const points = await Api.edost.getPoints(cityName)
        dispatch({type: 'SET_EDOST_POINT', payload: points})
    }
}

export const fetchDomains = () => {
    return async (dispatch) => {
        const domains = await Api.server.getDomains()
        dispatch({type: 'SET_DOMAINS', payload: domains})
    }
}
