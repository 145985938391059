import {Api} from "../../api";
import {setTextCity} from '@/utils';

export const fetchServices = (Redis, reset = false) => {
    return async (dispatch) => {
        const data = await Api.services.getServices(Redis, reset)
        dispatch({type: 'FETCH_SERVICES', payload: data})
    }
}

export const fetchService = (Redis, code, reset = false) => {
    return async (dispatch) => {
        const response  = await Api.services.getService(Redis, code, reset)
        dispatch({type: 'FETCH_SERIVCE', payload: response})
    }
}


export const fetchServiceInstall = (Redis, code, host, reset = false) => {
    return async (dispatch) => {
        const response  = await Api.services.getServiceInstall(Redis, code, Boolean(reset))
        const domains = await Api.server.getDomains(Redis, Boolean(reset));
        response.name = setTextCity(host, domains, response.name);
        response.detailText = setTextCity(host, domains, response.detailText);
        dispatch({type: 'FETCH_SERIVCE_INSTALL', payload: response})
    }
}