import {useSelector} from "react-redux";
import styles from "../../../../pages/shops/index.module.scss";
import Sldier from "../../../common/slider";
import Adv from "../../../main/adv";
import Team from "../../../common/team";
import {useMemo, useState} from "react";
import Maper from "../../contacts/maper";
import clsx from "clsx";


export default function ShopDetail({shop}) {
    const {shopReviews} = useSelector(state => state.content)
    const images = useMemo(() => {
        return shop.MEDIA_SRC?.map(picture => {
            return {
                thumb: picture.big,
                full: picture.big
            }
        })
    }, [shop])

    const reviewsFiltered = useMemo(() => {
        return shopReviews.filter(review => {
            return shop.NAME.includes(review.PROPERTY_CITYS_VALUE)
        })
    }, [shopReviews, shop]);

    const [pointId, setPointId] = useState(null)

    const currentShops = useMemo(() => {
        return [shop]
    }, [shop])

    const points = useMemo(() => {
        return currentShops;
    }, [currentShops])

    const zoom = useMemo(() => {
        return points.length > 2 ? 5 : 9
    }, [points])

    return (
        <div className={styles.Blocks}>
            <h1>{shop.NAME}</h1>
            {shop.DRIVE_SCHEME && <div className={styles.SchemeTop} onClick={() => {
                const selector = document.getElementById('map');
                window.scrollTo({top: selector.offsetTop + 100, behavior: 'smooth'})
                setPointId(shop.ID)
            }}>Схема проезда</div>}
            <div className={styles.Images}>
                <Sldier images={images}/>
            </div>
            <div className={styles.Adreses}>
                <div className={styles.Adr}>
                    {currentShops.map((shop, i) => (
                        <div key={i} className={styles.AdrBlock}>
                            {shop.DRIVE_SCHEME && (
                                <div className={clsx(styles.Scheme, shop.ID === pointId && styles.SchemeSelected)} onClick={() => setPointId(shop.ID)}>Схема проезда</div>
                            )}
                            <div>Адрес:</div>
                            <span dangerouslySetInnerHTML={{__html: shop['~ADR']}}/>
                            {shop.WORK_TIME && (
                                <>
                                    <div>Режим работы:</div>
                                    <span>{shop?.WORK_TIME}</span>
                                </>
                            )}
                        </div>
                    ))}
                </div>
                <div className={styles.Map} id="map">
                    <Maper
                        zoom={zoom}
                        points={points}
                        pointId={pointId}
                    />
                </div>
            </div>
            <div className={styles.Adv}>
                <h2>Наши преимущества</h2>
                <Adv rows={2}/>
            </div>
            <Team shopId={shop.ID}/>
            {shop.YOUTUBE?.length > 0 && (
                <div className={styles.Video}>
                    <h2>Видео магазина</h2>
                    <iframe
                        src={shop.YOUTUBE[0]}
                    >
                    </iframe>
                </div>
            )}
            <div>
                {reviewsFiltered.map(review => (
                    <>
                        {review.PROPERTY_TYPE_VALUE === 'Кнопка' && (
                            <a href={review.PROPERTY_LINK_VALUE}
                               target="_blank"
                               rel="noreferrer"
                               key={review.ID}
                               className={styles.Button}
                            >
                                <div className={styles.Img}>
                                    <img src={review.ICON_SRC}/>
                                </div>
                                <div className={styles.Name}>
                                    {review.NAME}
                                </div>
                                <div className={styles.Qnt}>
                                    Количество отзывов: {review.PROPERTY_COUNT_VALUE}
                                </div>
                                <div className={styles.Rating}>
                                    <div className="video-sources-block__rating-val">
                                        {review.PROPERTY_RATING_VALUE}
                                    </div>
                                </div>
                            </a>
                        )}
                        {review.PROPERTY_TYPE_VALUE === 'Виджет' && (
                            <div className={styles.Item} key={review.ID}>
                                <h3>{review.NAME}</h3>
                                <div className={styles.Frame} dangerouslySetInnerHTML={{ __html: review['~PREVIEW_TEXT'] }}/>
                            </div>
                        )}
                    </>
                ))}
            </div>
            <div className={styles.Requisite}>
                <div>
                    <h2>Реквизиты компании для физических лиц</h2>
                    <div dangerouslySetInnerHTML={{__html: shop.REQ_FIZ.TEXT}}/>
                </div>
                <div>
                    <h2>Реквизиты компании для юридических лиц</h2>
                    <div dangerouslySetInnerHTML={{__html: shop.REQ_UR.TEXT}}/>
                </div>
            </div>
        </div>
    )
}