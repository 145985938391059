import styles from './index.module.scss'
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Loader from "../../common/loader";
import Map from "../../pages/shops/map";

export default function Scheme() {
    const {data: shopCode} = useSelector(state => state.popup)
    const [shop, setShop] = useState({})
    const [loaded, setLoaded] = useState(false)
    const {shops} = useSelector(state => state.location)

    useEffect(async () => {
        setLoaded(false)
        setShop(shops[shopCode])
        setLoaded(true)
    }, [])

    return (
        <div className={styles.Wrapper}>
            {!loaded && (
                <div className={styles.Loader}>
                    <Loader/>
                </div>
            )}
            {loaded && (
                <div>
                    <h2>{shop.NAME}</h2>
                    <Map shop={shop}/>
                </div>
            )}
        </div>
    )
}
