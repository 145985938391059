import Button from "/components/common/button";
import styles from "./index.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {seaAgree, showPopup} from "../../../redux/action-creaters/popup";
import {popupTypes} from "../../../redux/reducers/popupReducer";
import Loader from "/components/common/loader"
import Link from "next/link";
import Checkbox from "../../common/checkbox";
import {useState} from "react";

export default function PopupFormWrapper({
    title,
    isLoading,
    isProcessing,
    onSubmit,
    errorText = '',
    successText = '',
    sentText = 'Отправить',
    children,
    topText
}) {
    const dispatch = useDispatch();
    const [isAgreeError, setIsAgreeError] = useState(false);
    const [isAgree, setIsAgree] = useState(true)
    const onHide = () => {
        dispatch(showPopup(popupTypes.none))
    }

    return (
        <>
            <h2>{title}</h2>
            {isLoading && (
                <div className={styles.Loader}>
                    <Loader/>
                </div>
            )}
            {successText && (
                <>
                    <span>{successText}</span>
                    <div className={styles.Buttom}>
                        <Button onClick={onHide} label={'Закрыть'}/>
                    </div>
                </>
            )}
            {!isLoading && !successText && (
                <>
                    <div className={styles.Content}>
                        {topText && <div className={styles.TopText}>
                            {topText}
                        </div>}
                        {errorText && (
                            <span className={styles.Error}>{errorText}</span>
                        )}
                        {isAgreeError && (
                            <span className={styles.Error}>Не установлена галочка соглашения с политикой обработки персональных данных</span>
                        )}
                        {children}
                        <div className={styles.Privacy} onClick={() => {
                            setIsAgree(!isAgree)
                        }}>
                            <Checkbox checked={isAgree}/>
                            <div>
                                Я принимаю условия <a target='_blank' href='/privacy/'>Пользовательского соглащения, Политики конфиденциальности</a> и даю согласие на обработку персональных данных
                            </div>
                        </div>
                    </div>
                    <div className={styles.Buttom}>
                        <Button isDisabled={!isAgree} onClick={() => {
                            setIsAgreeError(false)
                            if (isAgree) {
                                onSubmit()
                            } else {
                                setIsAgreeError(true)
                            }

                        }} isLoading={isProcessing} label={sentText}/>
                    </div>
                </>
            )}
        </>
    )
}
