import styles from './index.module.scss'
import {useEffect} from "react";
import clsx from 'clsx';
import Props from "../../../common/product/props";
import Button from "/components/common/button";
import {showPopup} from "../../../../redux/action-creaters/popup";
import {popupTypes} from "../../../../redux/reducers/popupReducer";
import {useDispatch, useSelector} from "react-redux";
import Files from "../files"
import Sert from "../sert";

const tabs = {
    description: 'description',
}

export default function BottomBaltex({product, isBaltex}) {
    useEffect(() => {
        setTimeout(() => {
            if (typeof window === 'undefined') {
                return
            }
            Object.values(tabs).map(tab => {
                if (window.location.hash === `#${tab}`) {
                    const section = document.getElementById(tab);
                    section.click()
                    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                }
            })
        }, 0);
    }, []);

    const dispatch = useDispatch();

    const {sert} = useSelector(state => state.product)

    return (
        <div className={clsx(isBaltex && styles.InfoBaltex, styles.Info)}>
            <div className="container">
                <br/>
                <h2 id='description'>
                    Описание
                </h2>

                <div>
                    {product['DETAIL_TEXT'] ? (
                        <div className={'text'}>
                            <div itemProp="description"
                                 dangerouslySetInnerHTML={{__html: product['DETAIL_TEXT']}}></div>
                        </div>
                    ) : (
                        <div>
                            <p>Нет описания. Если у вас появились вопросы по товару - закажите обратный звонок:</p>
                            <div>
                                <Button customStyles={styles.FormButton}
                                        onClick={() => dispatch(showPopup(popupTypes.backCall))}
                                        label={'Заказать звонок'}/>
                            </div>
                        </div>
                    )}
                    {product.FILE && (
                        <div className={styles.Bottom}>

                            {product.FILE && (
                                <div className={styles.Docs}>
                                    <div className={styles.SubTitle}>Комплектация</div>
                                    <Files file={product.FILE} isBaltex={true}/>
                                    <Sert sert={sert} isBaltex={true}/>
                                </div>
                            )}
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}

