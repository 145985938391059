import {useEffect, useMemo, useState} from "react";
import {getBacket} from "../../../redux/action-creaters/cart";
import {useDispatch, useSelector} from "react-redux";
import styles from "./index.module.scss";
import Button from "/components/common/button"
import {useRouter} from "next/router";
import {showPopup} from "../../../redux/action-creaters/popup";
import {popupTypes} from "../../../redux/reducers/popupReducer";
import BasketItems from "/components/common/basketItems"
import {declOfNum} from "../../../utils";

export default function Cart() {
    const router = useRouter();
    const dispatch = useDispatch();
const [isLoading, setLoading] = useState(true)
    useEffect(() => {
        const loadData = async () => {
            await dispatch(getBacket())
}
        loadData().then(() => {
            setLoading(false)
        })
    }, []);

    const {basket} = useSelector(state => state.cart)

    const sum = useMemo(() => {
        return basket?.PRICE
    }, [basket])

    return (
        <div className={styles.Basket}>
            <h2>Товар добавлен в корзину</h2>
            <BasketItems basket={basket}/>
            <div className={styles.Total}>
                <div className={styles.TotalItem}>
                    <div>В корзине { declOfNum(basket?.QTY, ['товар', 'товара', 'товаров']) }</div>
                    <span>на сумму { sum } руб</span>
                </div>
                <div className={styles.TotalButtons}>
                    <Button
                        onClick={() => { dispatch(showPopup(popupTypes.none)) }}
                        type={'outline'}
                        label={'Продолжить покупки'}
                    />
                    <Button
                        onClick={() => { router.push('/personal/cart/') }}
                        label={'Оформить заказ'}
                    />
                </div>
            </div>
        </div>
    )
}
