
export default function Icon(
{
    id,
    width = 20,
    height,
    size,
    fill = '#fff0',
    stroke = '',
    strokeWidth,
    customStyles = ''
}: any) {
    return (
        <svg width={size ?? width} height={size ?? height} style={{fill, stroke, strokeWidth}} className={customStyles}>
            <use href={`/icons.svg#${id}`} />
        </svg>
    );
}
