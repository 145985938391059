import Input from "../../common/input";
import {useEffect, useState} from "react";
import {Api} from "/api";
import {useSelector} from "react-redux";
import PopupFormWrapper from "../popupFormWrapper"
import styles from "./index.module.scss";

export default function Vacancy() {
    const [fields, setFields] = useState({});
    const [facList, setFacList] = useState({});
    const [result, setResult] = useState({});
    const [success, setSuccess] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const masks = {
        'Телефон': '+9 (999) 999 99-99'
    }

    const submit = async () => {
        setIsProcessing(true)
        const data = await Api.forms.vacancy(result);
        if (data.success) {
            setSuccess('Заявка отправлена.')
        } else if (data.message) {
            setError(data.message)
            setFields(data.fields);
        }
        setIsProcessing(false)
    }

    const {data} = useSelector(state => state.popup)

    useEffect(async () => {
        setLoading(true)
        setFields(await Api.forms.vacancy({getFields: true}));
        setFacList(await Api.content.getVacancies());
        setResult({form_text_22: data});
        setLoading(false)
    }, [])

    return (
        <PopupFormWrapper
            title='Отклик на вакансию'
            isLoading={isLoading}
            isProcessing={isProcessing}
            onSubmit={submit}
            errorText={error}
            successText={success}
        >
            {Object.values(fields).map(({NAME, CODE, REQUIRED, ERROR, TYPE}) => (
                <>
                    {CODE === "form_text_22" ? (
                        <select
                            className={styles.Section}
                            onChange={(event) => setResult({...result, [CODE]: event.target.value})}
                        >
                            {Array.isArray(facList) && facList.map(item => <option key={item.ID} selected={item.NAME === result.form_text_22}>{item.NAME}</option>)}
                        </select>
                    ) : (
                        <Input
                            key={CODE}
                            label={NAME}
                            required={REQUIRED}
                            mask={masks[NAME]}
                            onChange={(value) => setResult({...result, [CODE]: value})}
                            isError={ERROR}
                            isMultiple={TYPE === 'textarea'}
                            value={result[CODE]}
                        />
                    )}
                </>
            ))}
        </PopupFormWrapper>
    )
}
