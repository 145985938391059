import Input from "../../common/input";
import {useEffect, useMemo, useState} from "react";
import {Api} from "@/api";
import {useDispatch, useSelector} from "react-redux";
import PopupFormWrapper from "../popupFormWrapper"
import Mini from "@/components/common/product/mini"
import { UserFields } from '@/types/cart';
import Checkbox from "@/components/common/checkbox";
import {fetchSections} from "~/redux/action-creaters/sections";
import {fetchBrands} from "~/redux/action-creaters/brands";

export default function BuyOneClick() {
    const [fields] = useState([
        {
            NAME: 'Имя',
            CODE: UserFields.name,
            REQUIRED: true,
            ERROR: false
        },
        {
            NAME: 'Телефон',
            CODE: UserFields.phone,
            REQUIRED: true,
            ERROR: false
        },
        {
            NAME: 'Электронная почта',
            CODE: UserFields.email,
            REQUIRED: false,
            ERROR: false
        }
    ]);
    const [result, setResult] = useState({});
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isNeedInstall, setIsNeedInstall] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const {cityName, deliveyCityData} = useSelector((state: any) => state.location)

    const masks: any = {
        [UserFields.phone]: '+9 (999) 999 99-99'
    }

    const {products} = useSelector((state: any) => state.section)
    const {product, photos} = useSelector((state: any) => state.product)

    const {data} = useSelector((state: any) => state.popup)

    const productData: any = useMemo(() => {
        if (product.NAME && product?.ID === data.id) {
            return {
                name: product.NAME,
                url: product.DETAIL_PAGE_URL,
                price: product.CATALOG?.MIN_PRICE,
                photo: photos?.[0]?.[0],
                sectionId: product.IBLOCK_SECTION_ID,
                brand:  product.BRAND?.name,
            }
        }
        const catalogProduct = products.find(({ID}: any) => ID === data.id)
        if (catalogProduct) {
            return {
                name: catalogProduct.NAME,
                url: catalogProduct.DETAIL_PAGE_URL,
                price: catalogProduct.CATALOG?.MIN_PRICE,
                photo: catalogProduct.PICTURES_PATH?.[0],
                sectionId: catalogProduct.IBLOCK_SECTION_ID,
                brand:  catalogProduct.BRAND?.name,
            }
        }
        return {}
    }, [data])

    const {sections} = useSelector((state: any) => state.sections)
    const {items} = useSelector((state: any) => state.brands)
    const submit = async () => {
        setIsProcessing(true)
        const dataRes: any = await Api.cart.initOrder({
            ...result,
            [UserFields.city]: `${cityName}, ${deliveyCityData?.region_with_type}`,
            BUY_ONE_CLICK: true
        });
        if (dataRes.ORDER_ID) {
            setSuccess(`Заказ создан. Номер вашего заказа #${dataRes.ORDER_ID}`)

            // @ts-ignore
            if (typeof ym !== "undefined") {
                // @ts-ignore
                ym(20518312, 'reachGoal', 'byu1clickConfirm')
            }

            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore
            window.dataLayer.push({
                "ecommerce": {
                    "currencyCode": "RUB",
                    "purchase": {
                        "actionField": { "id": dataRes.ORDER_ID, "total": productData.price },
                        "products": [
                            {
                                "id": data.id,
                                "quantity": 1,
                                "name": productData.name,
                                "price": productData.price,
                                "brand": productData.brand,
                                "category": sections[productData.sectionId].NAME,
                            },
                        ]
                    }
                }
            });
        } else if (dataRes.message) {
            setError(dataRes.message)
        }
        setIsProcessing(false)
    }


    useEffect(() => {
        if (data.id) {
            setResult({PRODUCT_ID: data.id, PRODUCT_PRICE: data.price});
        }
    }, [data])

    const dispatch = useDispatch();
    useEffect(() => {
        if (sections.length === 0) {
            dispatch(fetchSections())
        }
    }, []);

    return (
        <PopupFormWrapper
            title={data.id ? 'Купить в 1 клик' : 'Быстрый заказ'}
            isLoading={false}
            isProcessing={isProcessing}
            onSubmit={submit}
            errorText={error}
            successText={success}
            topText={'Наш оператор свяжется с вами и оформит заказ за вас, предварительно уточнив все детали.'}
        >
            {data.id && (
                <Mini productId={data.id} price={data.price}/>
            )}

            {fields.map(({NAME, CODE, REQUIRED, ERROR}) => (
                <Input
                    key={CODE}
                    label={NAME}
                    required={REQUIRED}
                    mask={masks[CODE]}
                    onChange={(value: any) => setResult({...result, [CODE]: value})}
                    isError={ERROR}
                />
            ))}

            {['Москва', 'Санкт-Петербург'].includes(cityName) && (
                <Checkbox
                    onChange={() => {
                        setResult({...result, NEED_INSTALL: !isNeedInstall});
                        setIsNeedInstall(!isNeedInstall)
                    }}
                    checked={isNeedInstall}
                    label='Добавить услугу установки'
                />
            )}
        </PopupFormWrapper>
    )
}