import Input from "../../common/input";
import {useEffect, useMemo, useState} from "react";
import {Api} from "/api";
import styles from "./index.module.scss";
import {useSelector} from "react-redux";
import PopupFormWrapper from "../popupFormWrapper"

export default function AddComment() {
    const [fields, setFields] = useState({});
    const [result, setResult] = useState({});
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const submit = async () => {
        setIsProcessing(true)
        const data = await Api.forms.addComment(result);
        if (data.success) {
            setSuccess(`Комментарий отправлен. Номер комментария #${data.success}`)
        } else if (data.message) {
            setError(data.message)
            setFields(data.fields);
        }
        setIsProcessing(false)
    }

    const {data} = useSelector(state => state.popup)

    useEffect(async () => {
        setLoading(true)
        const {fields} = await Api.forms.addComment({getFields: true})
        setResult({ID: data.id});
        setFields(fields);
        setLoading(false)
    }, [])

    const fieldsFiltered = useMemo(() => {
        return Object.values(fields).filter(({CODE}) => CODE !== 'ID')
    }, [fields])

    return (
        <PopupFormWrapper
            title='Новый комментарий'
            isLoading={isLoading}
            isProcessing={isProcessing}
            onSubmit={submit}
            errorText={error}
            successText={success}
        >
            {fieldsFiltered.map(({NAME, CODE, REQUIRED, ERROR, TYPE}) => (
                <Input
                    key={CODE}
                    label={NAME}
                    required={REQUIRED}
                    onChange={(value) => setResult({...result, [CODE]: value})}
                    isError={ERROR}
                    isMultiple={TYPE === 'HTML'}
                />
            ))}
        </PopupFormWrapper>
    )
}