import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Loader from "/components/common/loader"
import styles from "./index.module.scss";
import ShopDetail from "../../pages/shops/detail";
import {fetchMainAdv, fetchShopReviews, fetchTeam} from "../../../redux/action-creaters/content";

export default function FastViewService() {
    const dispatch = useDispatch();
    const {data: shopCode} = useSelector(state => state.popup)
    const [shop, setShop] = useState({})
    const [loaded, setLoaded] = useState(false)
    const {shops} = useSelector(state => state.location)
    const {mainAdv, team, shopReviews} = useSelector(state => state.content)

    useEffect(async () => {
        setLoaded(false)
        if (team.length === 0) {
            await dispatch(fetchTeam())
        }
        if (mainAdv.length === 0) {
            await dispatch(fetchMainAdv())
        }
        if (shopReviews.length === 0) {
            await dispatch(fetchShopReviews())
        }
        setShop(shops[shopCode])

        setLoaded(true)
    }, [])

    return (
        <div className={styles.Wrapper}>
            {!loaded && (
                <div className={styles.Loader}>
                    <Loader/>
                </div>
            )}
            {loaded && (
                <div>
                    <h2>{shop.NAME}</h2>
                    <ShopDetail shop={shop}/>
                </div>
            )}
        </div>
    )
}
