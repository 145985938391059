import styles from './index.module.scss'
import Auth from "../../common/auth";
import {useDispatch, useSelector} from "react-redux";
import SliderComponent from '/components/common/slider'

export default function Slider() {
    const {data} = useSelector(state => state.popup)

    return (
        <div className={styles.Content}>
            <SliderComponent images={data.images}/>
        </div>
    )
}
