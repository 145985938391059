import styles from './index.module.scss'
import Auth from "../../common/auth";

export default function AuthPopup({onHide}) {
    return (
        <div className={styles.Content}>
            <h2>Авторизация</h2>
            <Auth onConfirm={onHide}/>
        </div>
    )
}
