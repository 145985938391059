import {Api} from "../../api";
import {cacheFunctionData} from '/utils'

export const getCartData = (isConfirm = false) => {
    return async (dispatch, getState) => {
        const {cart} = getState()
        const params = {...cart.params}
        params.confirmorder = isConfirm ? 'Y' : 'N'
        const {data} = await Api.cart.getCart(params)
        dispatch({type: 'SET_CART_DATA', payload: data})
    }
}

export const getDeliveryList = (Redis = false, reset = false) => {
    return async (dispatch) => {
        const response = await Api.cart.getDeliveryList(Redis, reset)
        dispatch({type: 'SET_DELIVERY_LIST', payload: response})
    }
}

export const setDeliveryListAdditional = (list) => {
    return async (dispatch) => {
        dispatch({type: 'SET_DELIVERY_LIST_ADDITIONAL', payload: list})
    }
}

export const getPaymentList = (Redis = false, reset = false) => {
    return async (dispatch) => {
        const response = await Api.cart.getPaymentList(Redis, reset)
        dispatch({type: 'SET_PAYMENT_LIST', payload: response})
    }
}

export const getBacket = (reset = false) => {
    return async (dispatch) => {
        const data = await cacheFunctionData(Api.basket.getBasket, 'basket', reset)
        dispatch({type: 'SET_BASKET', payload: data})
    }
}

export const setParam = (param) => {
    return async (dispatch) => {
        dispatch({type: 'SET_CART_PARAM', payload: param})
    }
}

export const setParams = (params) => {
    return async (dispatch) => {
        dispatch({type: 'SET_CART_PARAMS', payload: params})
    }
}