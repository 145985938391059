import styles from './index.module.scss'
import clsx from "clsx";

export default function Checkbox({onChange, checked, label}) {
    return (
        <div
            className={styles.Checkbox}
            onClick={onChange}>
            <span className={clsx(styles.Box, checked && styles.BoxChecked)}/>
            <span>{label}</span>
        </div>
    )
}
