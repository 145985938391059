import {useEffect, useMemo, useState} from "react";
import { YMaps, Map, Clusterer, Placemark, Polyline } from "react-yandex-maps";
import styles from "./index.module.scss"
import React from "react";
import ReactDOM from "react-dom";
import Balloon from '../balloon';
import ReactDOMServer from "react-dom/server";

const BalloonContentLayout = (layoutFactory, Component) => {
    const html = ReactDOMServer.renderToString(Component);
    const Layout = layoutFactory.createClass(`<div id="balloon">${html}</div>`, {
        build: function() {
            Layout.superclass.build.call(this);
        }
    });

    return Layout;
};

export default function Maper({points, pointId, zoom}) {
    const [ymaps, setYmaps] = useState(null)
    const [selectedPoint, setSelectedPoint] = useState(null)
    const [mapRef, setMapRef] = useState(null)

    const onPlacemarkClick = point => () => {
        setSelectedPoint(point)
    };

    const pointCoords = useMemo(() => {
        return points.map(point => {
            return [
                point['GPS_N'],
                point['GPS_S']
            ]
        })
    }, [points])

    useEffect(() => {
        if (ymaps && mapRef && pointCoords.length > 0) {
            mapRef.setBounds(ymaps.util.bounds.fromPoints(pointCoords));
            mapRef.setZoom(zoom);
        }
    }, [ymaps, mapRef, pointCoords])

    useEffect(() => {
        if (ymaps && mapRef && pointId) {
            const point = points.find(point => point.ID === pointId);
            mapRef.setCenter([point['GPS_N'], point['GPS_S']]);
            mapRef.setZoom(16);
        }
    }, [pointId, ymaps, mapRef])

    return (
        <div>
            <YMaps query={{ lang: "ru_RU", load: "package.full" }}>
                <Map
                    onLoad={ymaps => setYmaps(ymaps)}
                    className={styles.Map}
                    defaultState={{ center: [0, 0], zoom: 9 }}
                    instanceRef={(ref) => {
                        if (ref) {
                            setMapRef(ref);
                        }
                    }}
                >
                    {ymaps && points.map((point) => point.DRIVE_SCHEME && (
                        <Polyline
                            key={point.ID}
                            geometry={JSON.parse(point.DRIVE_SCHEME)}
                            options={{
                                balloonCloseButton: false,
                                strokeColor: '#1247a5',
                                strokeWidth: 4,
                                strokeOpacity: 0.5
                            }}
                        />
                    ))}
                    <Clusterer
                        options={{
                            preset: "islands#invertedVioletClusterIcons",
                        }}
                    >
                        {ymaps && points.map((point, index) => (
                            <Placemark
                                modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
                                key={point.ID}
                                geometry={[point.GPS_N, point.GPS_S]}
                                onClick={onPlacemarkClick(point)}
                                onBalloonOpen={() => {
                                    ReactDOM.hydrate(
                                        <Balloon
                                            point={point}
                                            pointId={pointId}
                                        />,
                                        document.getElementById("balloon")
                                    );
                                }}
                                options={{
                                    balloonContentLayout: BalloonContentLayout(
                                        ymaps.templateLayoutFactory,
                                        <Balloon
                                            point={point}
                                            pointId={pointId}
                                        />
                                    ),
                                }}
                            />
                        ))}
                    </Clusterer>
                </Map>
            </YMaps>
        </div>
    )
}