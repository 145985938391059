import styles from './index.module.scss'
import Auth from "../../common/auth";
import {useDispatch, useSelector} from "react-redux";

export default function Video() {
    const {data} = useSelector(state => state.popup)

    return (
        <div className={styles.Content}>
            <iframe src={`https://www.youtube.com/embed/${data}?rel=0&autoplay=1`}></iframe>
        </div>
    )
}
