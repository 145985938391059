import styles from "../../../../pages/shops/index.module.scss";
import {useEffect, useMemo, useState} from "react";
import Maper from "../../contacts/maper";

export default function Map({shop}) {
    const [pointId, setPointId] = useState(null)

    const currentShops = useMemo(() => {
        return [shop]
    }, [shop])

    const points = useMemo(() => {
        return currentShops;
    }, [currentShops])

    const zoom = useMemo(() => {
        return points.length > 2 ? 5 : 9
    }, [points])

    useEffect(() => {
        setPointId(shop.ID)
    }, []);

    return (
        <div className={styles.Blocks}>
            <div className={styles.Adreses}>

                <div className={styles.Map}>
                    <Maper
                        zoom={zoom}
                        points={points}
                        pointId={pointId}
                    />
                </div>
            </div>
        </div>
    )
}