import styles from './index.module.scss'
import Auth from "../../common/auth";
import {useDispatch, useSelector} from "react-redux";
import {useRouter} from "next/router";
import Button from "/components/common/button"
import Questions from "/components/pages/product/bottom/questions";
import Mini from "/components/common/product/mini"
import {showPopup} from "/redux/action-creaters/popup";
import {popupTypes} from "/redux/reducers/popupReducer";

export default function ProductQuestions() {
    const {data} = useSelector(state => state.popup)
    const router = useRouter();

    const dispatch = useDispatch();
    return (
        <div className={styles.Content}>
           
            {data.id && (
                <Mini productId={data.id}/>
            )}
            <br/>
             <h1>Вопросы:</h1>
            {data.id > 0 ? (
                <Questions productId={data.id}/>
            ) : (
                <p>Пока нет ни одного вопроса, будьте первым!</p>
            )}
            <Button
                customStyles={styles.FormButton}
                onClick={() => dispatch(showPopup(popupTypes.addQuestion, {
                    id: data.id
                }))}
                label={'Задать вопрос по товару'}
            />
        </div>
    )
}
