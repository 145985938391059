import styles from './index.module.scss'
import Auth from "../../common/auth";
import {useDispatch, useSelector} from "react-redux";
import {useRouter} from "next/router";
import Reviews from "/components/pages/product/bottom/reviews";
import Button from "/components/common/button"
import Mini from "/components/common/product/mini"
import {showPopup} from "/redux/action-creaters/popup";
import {popupTypes} from "/redux/reducers/popupReducer";

export default function ProductReviews() {
    const {data} = useSelector(state => state.popup)
    const router = useRouter();

    const dispatch = useDispatch();
    return (
        <div className={styles.Content}>
            {data.id && (
                <Mini productId={data.id}/>
            )}
                     <br/>
             <h1>Отзывы:</h1>
            {data.id > 0 ? (
                <Reviews productId={data.id}/>
            ) : (
                <p>Пока нет ни одного отзыва, будьте первым!</p>
            )}
            <Button
                customStyles={styles.FormButton}
                onClick={() => dispatch(showPopup(popupTypes.addReview, {
                    id: data.id
                }))}
                label={'Оставить отзыв о товаре'}
            />
        </div>
    )
}
