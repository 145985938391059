export function setLocationLogic(cityName, cityData = {}, domains) {

    if (window.location.hostname.includes('localhost') || (window.location.hostname.includes('farkop-') && !window.location.hostname.includes('farkop-msk')) || window.location.hostname.includes('baltex')) {
        window.location.reload()
        return
    }

    let baseDomain = 'farkop.ru'
    if (window.location.hostname.includes('farkop-msk.ru')) {
        baseDomain = 'farkop-msk.ru';
    }
    if (cityName === 'Москва' || cityData.region === 'Московская' || cityData.region === 'Москва') {
        if (window.location.hostname !== `msk.${baseDomain}`) {
            window.location.href = `https://msk.${baseDomain}${window.location.pathname}${window.location.search}`;
        }
        return;
    }
    if (cityName === 'Санкт-Петербург' || cityData.region === 'Санкт-Петербург' || cityData.region === 'Ленинградская') {
        if (window.location.hostname !== `spb.${baseDomain}`) {
            window.location.href = `https://spb.${baseDomain}${window.location.pathname}${window.location.search}`;
        }
        return;
    }

    const citys = Object.values(domains);
    let cityRegionIndex = citys.findIndex(city => city['NAME'] === cityName);

    if (cityRegionIndex > -1) {
        const domain = Object.keys(domains)[cityRegionIndex];
        if (window.location.hostname !== `${domain}.${baseDomain}`) {
            window.location.href = `https://${domain}.${baseDomain}${window.location.pathname}${window.location.search}`;
        }
    } else if (window.location.hostname !== `${baseDomain}`) {
        window.location.href = `https://${baseDomain}${window.location.pathname}${window.location.search}`;
    }
}